import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Groups are a special kind of track region that are just a container for other regions.`}</p>
    <p>{`Create a group by setting a region's `}<inlineCode parentName="p">{`regions`}</inlineCode>{` property:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const myGroup = {
  start: 1000,
  loop: true,
  regions: [
    { duration: 1000, state: { x: { to: 5 } } },
    { duration: 2000, state: { x: { to: 0 } } }
  ]
};
`}</code></pre>
    <p>{`As you can see in the example, groups can have many of the same properties singular (or `}<strong parentName="p">{`atom`}</strong>{`) track regions can have.
Specifically, track region groups share these properties with track region atoms:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`end`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`easing`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`interp`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`layer`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`loop`}</inlineCode></li>
    </ul>
    <p>{`A group may not have these properties, however:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`state`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`duration`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "inheriting-properties"
    }}>{`Inheriting Properties`}</h2>
    <p>{`If `}<inlineCode parentName="p">{`easing`}</inlineCode>{` or `}<inlineCode parentName="p">{`interp`}</inlineCode>{` is set on a group, then that function will become the default `}<inlineCode parentName="p">{`easing`}</inlineCode>{` or `}<inlineCode parentName="p">{`interp`}</inlineCode>{` for every child of the group.
Just like normal, child regions may override their default easing and interpolation functions.`}</p>
    <h2 {...{
      "id": "layer-namespaces"
    }}>{`Layer Namespaces`}</h2>
    <p>{`When animations are generated, React Ensemble treats groups essentially like self-contained animations.
Therefore, layers are handled a bit differently than they are in a single-dimensional track.`}</p>
    <p>{`Child regions' layers are `}<strong parentName="p">{`namespaced`}</strong>{` by their parent's layer name according to this pattern: `}<inlineCode parentName="p">{`"parent-layer-name.layer-name"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const track = [
  {
    layer: "top",
    regions: [
      { layer: "child", duration: 0 } // Will have layer = "top.child"
    ]
  }
];
`}</code></pre>
    <p>{`For example, a region with the layer `}<inlineCode parentName="p">{`"foo"`}</inlineCode>{` in a group will not be in the same layer as `}<inlineCode parentName="p">{`"foo"`}</inlineCode>{` at the top level.`}</p>
    <h2 {...{
      "id": "relative-time"
    }}>{`Relative Time`}</h2>
    <p>{`Groups have an optional property `}<inlineCode parentName="p">{`relativeTime`}</inlineCode>{` that sets whether or not child regions' timestamps will be considered relative to the group's start time.`}</p>
    <p>{`Consider the following group:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const myGroup = {
  start: 1000,
  regions: [
    { start: 2000, state: { x: { to: 5 } } },
    { duration: 1000, state: { x: { to: 0 } }, loop: { until: 5000 } }
  ]
};
`}</code></pre>
    <p>{`There are two ways to interpret the `}<inlineCode parentName="p">{`start`}</inlineCode>{` and `}<inlineCode parentName="p">{`loop.until`}</inlineCode>{` properties of the group's children.`}</p>
    <p>{`First, we can consider these times `}<strong parentName="p">{`absolute`}</strong>{`. Every timestamp in a group with absolute timing is considered relative to the start of the `}<em parentName="p">{`animation`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const myGroup = {
  start: 1000,
  relativeTime: false, // This is false by default, so including it is not needed
  regions: [
    { start: 2000, state: { x: { to: 5 } } }, // Start at 2 seconds (1 second after the group starts)
    { duration: 1000, state: { x: { to: 0 } }, loop: { until: 5000 } } // Loop until 5 seconds (4 seconds after the group starts)
  ]
};
`}</code></pre>
    <p>{`Alternatively, we can consider these times `}<strong parentName="p">{`relative`}</strong>{` to the start of the `}<em parentName="p">{`group`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const myGroup = {
  start: 1000,
  relativeTime: true,
  regions: [
    { start: 2000, state: { x: { to: 5 } } }, // Start at 3 seconds (2 seconds after the group starts)
    { duration: 1000, state: { x: { to: 0 } }, loop: { until: 5000 } } // Loop until 6 seconds (5 seconds after the group starts)
  ]
};
`}</code></pre>
    <h2 {...{
      "id": "further-reading"
    }}>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#trackregiongroup"
        }}>{`TrackRegionGroup API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      